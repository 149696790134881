@import 'webresources-sources/scss/imports';

.tabs-area {
  .tabs {
    .nav-tabs {
      .nav-item {
        .nav-link {
          color: var(--focus-color) !important;

          &.active {
            color: var(--text-color) !important;

            &::after {
              background: var(--accent-color) !important;
            }
          }

          &:hover {
            color: var(--accent-hover-color) !important;

            &::after {
              background: var(--accent-hover-color) !important;
            }
          }
        }
      }
    }
  }
}
