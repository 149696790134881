/* bootstrap overwrite */
.tabs-area .tabs .nav-tabs .nav-item .nav-link {
  color: var(--focus-color) !important;
}
.tabs-area .tabs .nav-tabs .nav-item .nav-link.active {
  color: var(--text-color) !important;
}
.tabs-area .tabs .nav-tabs .nav-item .nav-link.active::after {
  background: var(--accent-color) !important;
}
.tabs-area .tabs .nav-tabs .nav-item .nav-link:hover {
  color: var(--accent-hover-color) !important;
}
.tabs-area .tabs .nav-tabs .nav-item .nav-link:hover::after {
  background: var(--accent-hover-color) !important;
}